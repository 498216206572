var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents-section"},[_c('div',{staticClass:"title-section"},[_c('h2',{staticClass:"d-flex align-start"},[_vm._v(" 그룹관리 "),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-1",attrs:{"icon":"","loading":!_vm.groupLoad,"color":"primary"},on:{"click":_vm.getGroupList}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("새로고침")])])],1)]),(_vm.groupLoad === false && _vm.userLoad === false)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"main-section"},[_c('div',{staticClass:"groupWrap"},[_c('div',{staticClass:"topGroup",class:_vm.active.length === 0?'active primary--text':'',on:{"click":function($event){_vm.active = []}}},[_c('v-chip',{staticClass:"mr-1 tranColor",attrs:{"small":"","color":_vm.active.length === 0?'primary':''}},[_vm._v(_vm._s(_vm.accountsCnt))]),_vm._v(" "+_vm._s(_vm.companyInfo.name)+" ")],1),(_vm.groupLoad === true)?_c('v-treeview',{attrs:{"items":_vm.groups,"hoverable":"","activatable":"","open":_vm.open,"active":_vm.active,"return-object":"","expand-icon":"mdi-chevron-down","item-children":"groups","item-key":"no","dense":""},on:{"update:open":function($event){_vm.open=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"tranColor",attrs:{"small":"","color":_vm.active.length > 0 && _vm.active[0].no===item.no?'primary':''}},[_vm._v(_vm._s(item.count))])]}}],null,false,1230264406)}):_vm._e()],1),_c('div',{staticClass:"accountWrap"},[(_vm.groupLoad === true)?_c('div',{staticClass:"topBar mb-2"},[_c('div',{staticClass:"title"},[_c('v-breadcrumbs',{staticClass:"py-0 pl-0",attrs:{"items":_vm.curruntPath,"large":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"pointer",on:{"click":function($event){return _vm.toCurrentPath(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)}),_c('v-btn',{staticClass:"borderBtn ml-2 pl-2",attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.groupAddModalOpen}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" 그룹추가 ")],1)],1),(_vm.active.length > 0)?_c('div',{staticClass:"btnWrap ml-4"},[_c('v-btn',{staticClass:"pl-3",attrs:{"depressed":""},on:{"click":_vm.groupUpdateModalOpen}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" 수정 ")],1),(!_vm.active[0].groups)?_c('v-btn',{staticClass:"ml-2 pl-3",attrs:{"color":"error","depressed":""},on:{"click":_vm.deleteConfirmOpen}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v("삭제")],1):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.users.length > 0)?_c('v-simple-table',{staticClass:"mt-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.users),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-center",attrs:{"width":"10"}},[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.position))])],1),_c('td',{attrs:{"width":"200"}},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.id)+") "),(_vm.getGroupLeaderBool(item.id))?_c('v-chip',{staticClass:"ml-1 pl-2 pr-2",attrs:{"small":"","color":"success","close":""},on:{"click:close":function($event){return _vm.leaderClear(item.id)}}},[_vm._v("리더")]):_vm._e()],1)]),_c('td',[_vm._v(_vm._s(_vm.comm.getGroupFullPath(_vm.groups, item.groupNo, '그룹없음')))]),(_vm.active.length > 0 && (_vm.active[0].deputyHead === null
                  || _vm.active[0].head === null)
                  && !_vm.getGroupLeaderBool(item.id))?_c('td',{staticClass:"text-right",attrs:{"width":"120"}},[_c('div',{staticClass:"d-flex align-start"},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.leaderSelection(item.id)}}},[_vm._v("리더설정")]),_c('v-tooltip',{attrs:{"top":"","black":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"qMark",attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" 그룹의 리더는 모든 하위그룹의 매출이력과 고객을 열람할 수 있으며, "),_c('br'),_vm._v(" 그룹당 최대 2계정 씩 지정할 수 있습니다. ")])])],1)]):_c('td')])}),0)]},proxy:true}],null,false,3941738012)}):(
          _vm.users.length === 0
        )?_c('div',{staticClass:"empty-msg mt-5"},[_vm._v("그룹에 소속된 계정이 없습니다.")]):_vm._e()],1)]),_c('dlg-user-group',{attrs:{"show":_vm.dialog.show,"groups":_vm.groups === undefined ? [] : _vm.groups,"active":_vm.active,"open":_vm.open,"type":_vm.dialog.type,"func":_vm.dialog.func,"thisGroup":_vm.dialog.thisGroup},on:{"update:show":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }