<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        그룹관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getGroupList"
              :loading="!groupLoad"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
    </div>
    <v-progress-linear
      v-if="groupLoad === false && userLoad === false"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div class="main-section" v-else>
      <div class="groupWrap">
        <div
          class="topGroup"
          :class="active.length === 0?'active primary--text':''"
          @click="active = []">
          <v-chip
            small
            class="mr-1 tranColor"
            :color="active.length === 0?'primary':''"
          >{{ accountsCnt }}</v-chip>
          {{ companyInfo.name }}
        </div>
        <v-treeview
          v-if="groupLoad === true"
          :items="groups"
          hoverable
          activatable
          :open.sync="open"
          :active.sync="active"
          return-object
          expand-icon="mdi-chevron-down"
          item-children="groups"
          item-key="no"
          dense
        >
          <template v-slot:prepend="{ item }">
            <v-chip
              class="tranColor"
              small
              :color="active.length > 0 && active[0].no===item.no?'primary':''"
            >{{ item.count }}</v-chip>
          </template>
        </v-treeview>
      </div>
      <div class="accountWrap">
        <div class="topBar mb-2" v-if="groupLoad === true">
          <div class="title">
            <v-breadcrumbs :items="curruntPath" class="py-0 pl-0" large>
              <template #[`item`]="{ item }">
                <v-breadcrumbs-item
                  class="pointer"
                  @click="toCurrentPath(item)"
                >
                  {{ item.name }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <v-btn depressed small color="primary"
              class="borderBtn ml-2 pl-2"
              @click="groupAddModalOpen"
            >
              <v-icon small>mdi-plus</v-icon>
              그룹추가
            </v-btn>
          </div>
          <div class="btnWrap ml-4" v-if="active.length > 0">
            <v-btn
              depressed
              @click="groupUpdateModalOpen"
              class="pl-3"
            >
              <v-icon class="mr-1" small>mdi-pencil</v-icon>  수정
            </v-btn>
            <v-btn
              v-if="!active[0].groups"
              class="ml-2 pl-3"
              color="error"
              depressed
              @click="deleteConfirmOpen"
            ><v-icon class="mr-1" small>mdi-trash-can-outline</v-icon>삭제</v-btn>
          </div>
        </div>
        <v-simple-table class="mt-5"
          v-if="users.length > 0"
        >
          <template #default>
            <tbody>
              <tr
                v-for="item in users"
                :key="item.id"
              >
                <td width="10" class="text-center">
                  <v-chip small>{{ item.position }}</v-chip>
                </td>
                <td width="200">
                  <div class="d-flex align-center">
                    {{ item.name }} ({{ item.id }})
                    <v-chip
                      small
                      class="ml-1 pl-2 pr-2"
                      v-if="getGroupLeaderBool(item.id)"
                      color="success"
                      close
                      @click:close="leaderClear(item.id)"
                    >리더</v-chip>
                  </div>
                </td>
                <td>{{ comm.getGroupFullPath(groups, item.groupNo, '그룹없음') }}</td>
                <td class="text-right" width="120"
                  v-if="active.length > 0 && (active[0].deputyHead === null
                    || active[0].head === null)
                    && !getGroupLeaderBool(item.id)"
                >
                  <div class="d-flex align-start">
                    <v-btn small depressed color="success"
                      @click="leaderSelection(item.id)"
                    >리더설정</v-btn>
                    <v-tooltip top black>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="qMark"
                          small
                          color="warning"
                          v-on="on"
                          v-bind="attrs"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span>
                        그룹의 리더는 모든 하위그룹의 매출이력과 고객을 열람할 수 있으며,
                        <br>
                        그룹당 최대 2계정 씩 지정할 수 있습니다.
                      </span>
                    </v-tooltip>
                  </div>
                </td>
                <td v-else></td>
                <!-- <td class="text-right" width="80">
                  <v-btn small depressed color="info">그룹변경</v-btn>
                </td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="empty-msg mt-5"
          v-else-if="
            users.length === 0
          "
        >그룹에 소속된 계정이 없습니다.</div>
        <!-- <v-progress-linear
          v-else-if="userLoad === false"
          indeterminate
          class="mt-5"
          color="primary"
        ></v-progress-linear> -->
      </div>
    </div>
    <dlg-user-group
      :show.sync="dialog.show"
      :groups="groups === undefined ? [] : groups"
      :active="active"
      :open="open"
      :type="dialog.type"
      :func="dialog.func"
      :thisGroup="dialog.thisGroup"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import DlgUserGroup from '@/components/dialog/DlgUserGroup.vue';
import * as TreeModel from 'tree-model';
import comm from '@/util/comm';

export default {
  name: 'Group',
  components: { DlgUserGroup },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    comm,
    accountsCnt: null,
    tree: new TreeModel({
      childrenPropertyName: 'groups',
      modelComparatorFn: null,
    }),
    dialog: {
      show: false,
      title: null,
      thisGroup: null,
      func: null,
      type: 0,
    },
    active: [],
    open: [],
    curruntPath: [],
    groups: [],
    users: [],
    userLoad: false,
    groupLoad: false,
  }),
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    deleteConfirmOpen() {
      const func = () => {
        this.$socket.emit('groups.delete', {
          no: this.active[0].no,
        }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '그룹이 삭제되었습니다.']);
            this.getGroupList();
            this.active = [];
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
        this.confirm({ show: false });
      };
      this.confirm({
        show: true,
        func,
        msg: `${this.comm.getGroupFullPath(this.groups, this.active[0].no)} 그룹을 정말 삭제할까요?<br/><br/><span class="error--text text-body-2">※그룹에 속한 모든 계정의 소속이 초기화됩니다.</span>`,
        btnText: '삭제',
        color: 'error',
      });
    },
    toCurrentPath(path) {
      if (path.created === 0) {
        this.active = [];
      } else {
        this.active = [path];
      }
    },
    getGroupLeaderBool(id, group = this.active[0]) {
      let returnBool = false;
      if (group == null) {
        returnBool = false;
      } else {
        const root = this.tree.parse({ groups: this.groups });
        const thisNode = root.first((node) => (node.model.no === group.no));
        returnBool = thisNode.model.head === id || thisNode.model.deputyHead === id;
      }
      return returnBool;
    },
    groupAddModalOpen() {
      this.dialog.show = true;
      this.dialog.type = 0;
      this.dialog.func = this.addGroupFunc;
    },
    groupUpdateModalOpen() {
      this.dialog.show = true;
      this.dialog.type = 1;
      this.dialog.thisGroup = JSON.parse(JSON.stringify(this.active[0]));
      this.dialog.func = this.updateGroupFunc;
    },
    addGroupFunc(parentNo, groupName) {
      this.progress(true);
      this.$socket.emit('groups.add', {
        parent: parentNo,
        name: groupName,
      }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '그룹이 등록되었습니다.']);
          this.getGroupList(1, resp.item[0]);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
        this.progress(false);
      });
    },
    updateGroupFunc(no, parentNo, groupName) {
      this.progress(true);
      this.$socket.emit('groups.update', {
        parent: parentNo,
        no,
        name: groupName,
      }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '그룹이 수정되었습니다.']);
          this.getGroupList(1, resp.item[0]);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
        this.progress(false);
      });
    },
    leaderSelection(id) {
      this.progress(true);
      let reqObj = null;
      if (this.active[0].head === null) {
        reqObj = { no: this.active[0].no, head: id };
      } else if (this.active[0].deputyHead === null) {
        reqObj = { no: this.active[0].no, deputyHead: id };
      }
      if (reqObj !== null) {
        this.$socket.emit('groups.update', reqObj, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '리더를 설정하였습니다.']);
            const root = this.tree.parse({ groups: this.groups });
            const thisNode = root.first((node) => (node.model.no === this.active[0].no));
            const valArr = [];
            thisNode.walk((node) => {
              valArr.push(node.model.no);
            });
            this.getGroupList(0, resp.item[0], [
              { condition: 'in', column: 'groupNo', value: valArr },
              {
                where: 'and',
                condition: 'ne',
                column: 'id',
                value: 'admin',
              },
            ]);
            this.progress(false);
          } else {
            this.progress(false);
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      } else {
        this.progress(false);
        this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
      }
    },
    leaderClear(id) {
      this.progress(true);
      let reqObj = null;
      if (this.active[0].head !== null && this.active[0].head === id) {
        reqObj = { no: this.active[0].no, head: null };
      } else if (this.active[0].deputyHead !== null && this.active[0].deputyHead === id) {
        reqObj = { no: this.active[0].no, deputyHead: null };
      }
      if (reqObj !== null) {
        this.$socket.emit('groups.update', reqObj, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '리더를 해제하였습니다.']);
            const root = this.tree.parse({ groups: this.groups });
            const thisNode = root.first((node) => (node.model.no === this.active[0].no));
            const valArr = [];
            thisNode.walk((node) => {
              valArr.push(node.model.no);
            });
            this.getGroupList(0, resp.item[0], [
              { condition: 'in', column: 'groupNo', value: valArr },
              {
                where: 'and',
                condition: 'ne',
                column: 'id',
                value: 'admin',
              },
            ]);
            this.progress(false);
          } else {
            this.progress(false);
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      } else {
        this.progress(false);
        this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
      }
    },
    getGroupList(callbackType = null, callbackVal = null, valArr = []) {
      this.groupLoad = false;
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.active = [];
          this.groupLoad = true;
          this.curruntPath = [{ name: this.companyInfo.name, created: 0 }];
          this.groups = resp.items.groups;
          this.accountsCnt = resp.items.count;
          if (callbackType === 0 && callbackVal) {
            this.active = [callbackVal];
          }
          if (callbackType === 1 && callbackVal) {
            this.active = [callbackVal];
            const root = this.tree.parse({ groups: this.groups });
            const thisNode = root.first((node) => (node.model.no === callbackVal.no));
            const openArr = [];
            thisNode.getPath().forEach((r, i) => {
              if (i > 0) {
                openArr.push(r.model);
              }
            });
            this.open = openArr;
          }
          this.getUserList(valArr);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getUserList(arr = [{ condition: 'ne', column: 'id', value: 'admin' }]) {
      this.userLoad = false;
      this.users = [];
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: arr,
      }, (resp) => {
        if (resp.result === 'success') {
          this.users = resp.items;
          this.userLoad = true;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
  },
  watch: {
    active: {
      handler(val) {
        if (val.length === 0) {
          this.curruntPath = [{ name: this.companyInfo.name, created: 0 }];
          this.getUserList();
        } else {
          const root = this.tree.parse({ groups: this.groups });
          const thisNode = root.first((node) => (node.model.no === val[0].no));
          this.curruntPath = [
            { name: this.companyInfo.name, created: 0 },
            ...thisNode.getPath()
              .filter((r) => (r.model.no != null && r.model.no !== 0))
              .map((row) => (row.model)),
          ];
          const valArr = [];
          thisNode.walk((node) => {
            valArr.push(node.model.no);
          });
          this.getUserList([
            { condition: 'in', column: 'groupNo', value: valArr },
            {
              where: 'and',
              condition: 'ne',
              column: 'id',
              value: 'admin',
            },
          ]);
        }
      },
    },
    userLoad: {
      handler() {
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.main-section {
  display: flex;
  align-items: flex-start;
  .qMark {
    margin-top: -10px;
    margin-left: -2px;
  }
  .groupWrap {
    .tranColor {
      transition: .3s ease-in-out;
      overflow: hidden;
    }
    .topGroup {
      padding:10px;
      display: flex;
      align-items: center;
      transition: .3s ease-in-out;
      &.active {
        background-color: #E1E7F6 !important;
      }
      &:hover {
        background-color: #f6f6f6;
      }
    }
    flex-shrink: 0;
    width: 260px;
    // background-color: #f8f8f8;
    font-size: .9rem;
    border-radius: 4px;
    border:1px solid #ccc;
  }
  .accountWrap {
    padding-top:5px;
    padding-left: 20px;
    width: 100%;
    .topBar {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .btnWrap{
        flex-shrink: 0;
      }
      .title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 36px;
        .pointer {
          cursor: pointer;
        }
        .borderBtn {
          margin-top:2px;
        }
      }
    }
    .empty-msg {
      padding: 20px 20px;
      background-color: #f2f2f2;
      border-radius: 4px;
    }
  }
}
</style>
